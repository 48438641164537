import { Component } from '@angular/core'

import { Platform, NavController } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'

import { SettingsService } from './services/settings/settings.service'
import { UserService } from './services/user/user.service'
import { ExtraService } from './services/extra/extra.service'

import { File } from '@ionic-native/file/ngx'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { AlertService } from './services/alert/alert.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userService: UserService,
    private settingsService: SettingsService,
    private navCtrl: NavController,
    private extraService: ExtraService,
    private file: File,
    private appVersion: AppVersion,
    private alert: AlertService,

  ) {
    this.initializeApp()
  }

  initializeApp() {

    // Weiterleitung falls erforderlich
    let route = null
    if (document.URL.includes('signup'))
      route = 'signup'
    else if (document.URL.includes('event-details'))
      route = 'event-details'

    const params = route ? this.getParams(document.URL.split('?').pop()) : null

    // console.log('url: ', document.URL)
    // console.log('route', route)
    // console.log('params', params)
    // ---

    this.platform.ready().then(() => {

      // this.statusBar.styleDefault()
      this.statusBar.overlaysWebView(false)
      this.splashScreen.hide()

      Promise.all([
        this.userService.initialize(),
        this.settingsService.initialize(),
      ])
        .then(() => {

          if (this.file.externalDataDirectory) {
            console.log('On Device: Checking App version')
            this.checkAppVersion()
          }

          if (this.userService.isLoggedin)
            this.navCtrl.navigateRoot('/dashboard', { animated: false })
              .then(() => {
                if (route === 'event-details' && params && params['id'])
                  this.navCtrl.navigateForward(`/event-details?id=${params['id']}`)
              })
          else
            this.navCtrl.navigateRoot('/login', { animated: false })
              .then(() => {
                if (route === 'signup' && params && params['email'])
                  this.navCtrl.navigateForward(`/signup?email=${params['email']}`)
              })
        })
    })
  }

  getParams(str: string) {
    return str.split('&')
      .map(p => ({ key: p.split('=').shift(), val: p.split('=').pop() }))
      .reduce((map, obj) => {
        map[obj.key] = obj.val
        return map
      }, {})
  }

  async checkAppVersion() {
    try {
      const serverAppVersion = await this.extraService.getLatestAppVersionCode()
      const currentAppVersion = await this.appVersion.getVersionCode()

      console.log('on server: ', serverAppVersion)
      console.log('current: ', currentAppVersion)

      if (serverAppVersion > currentAppVersion) {
        if (await this.alert.confirmPrompt('Es gibt eine neue App-Version! Soll sie installiert werden?', 'Neue App-Version', false, false)) {
          const inputResult = await this.alert.inputPrompt(
            [
              { name: 'username', type: 'email', placeholder: 'Benutzername / E-Mail-Adresse' },
              { name: 'password', type: 'password', placeholder: 'Passwort' }
            ],
            'Neue App-Version', false)

          if (!inputResult)
            return
          else if (inputResult['username'] !== '' && inputResult['password'] !== '') {
            this.extraService.updateApp(inputResult['username'], inputResult['password']).catch((err) => {
              this.alert.errorHandler(err, 'Fehler beim Aktualizieren der App!')
            })
          }
          else
            this.alert.errorHandler('Wrong input!', 'Fehler beim Aktualizieren der App!')
        }
      }

    } catch (err) {
      this.alert.errorHandler(err, 'Fehler beim überprüfen der neusten version!')
    }
  }

  /* checkAppVersion() {
    return Promise.all([
      this.extraService.getLatestAppVersionCode(),
      this.appVersion.getVersionCode()
    ])
      .then(result => {
        console.log('on server: ', result[0])
        console.log('current: ', result[1])

        if (result[0] === result[1])
          return Promise.resolve()

        return this.alert.confirmPrompt('Es gibt eine neue App-Version! Soll sie installiert werden?', 'Neue App-Version')
          .then(alertResult => {
            if (alertResult)
              return this.alert.inputPrompt(
                [
                  { name: 'username', placeholder: 'Benutzername / E-Mail-Adresse' },
                  { name: 'password', type: 'password', placeholder: 'Passwort' }
                ],
                'Neue App-Version')
                .then(inputResult => {
                  if (!inputResult)
                    return
                  else if (inputResult['username'] !== '' && inputResult['password'] !== '') {
                    return this.extraService.updateApp(inputResult['username'], inputResult['password'])
                      .catch((err) => {
                        this.alert.errorHandler(err, 'Fehler beim Aktualizieren der App!')
                      })
                  }
                  else
                    this.alert.errorHandler('Wrong input!', 'Fehler beim Aktualizieren der App!')
                })
          })
      })
  } */
}
