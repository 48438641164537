import { SettingsService } from '../settings/settings.service'
import { IInvitee } from '../invitation/invitation.service'
import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'
import { HTTP } from '@ionic-native/http/ngx'

@Injectable({
  providedIn: 'root'
})
export class EventService {

  eventList: BehaviorSubject<IEvent[]> = new BehaviorSubject<IEvent[]>([])

  constructor(
    private http: HTTP,
    private settingsService: SettingsService
  ) { }

  setEventsList(list: IEvent[], currentUserId: number) {

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < list.length; i++)
      if (list[i].users.length !== 0) {
        list[i].ownerName = list[i].users.find(u => u.owner).name

        const currUser: IEventUser = list[i].users.find(u => u.userId === currentUserId)
        if (typeof currUser !== 'undefined') {
          list[i].takesPart = currUser.takesPart
          list[i].paid = currUser.paid
          list[i].takesPartComment = currUser.comment

          const buyer: string[] = list[i].users.filter(u => u.userId === currUser.hasTicket).map(u => u.name)
          list[i].hasTicketsFrom = buyer.length === 0 ? null : buyer[0]

          list[i].buysTicketsFor = list[i].users.filter(u => u.hasTicket === currentUserId).map(u => u.name)
          if (list[i].buysTicketsFor.length === 0)
            list[i].buysTicketsFor = null

        }
      }

    this.eventList.next(list)
  }

  /* updateList(token: string, currentUserId: number) {
    const url = `${this.settingsService.apiUrl}/event`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.get(url, {}, headers)
      .then(result => {
        const list: IEvent[] = JSON.parse(result.data)

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < list.length; i++) {
          list[i].ownerName = list[i].users.find(u => u.owner === true).name

          const currUser: IEventUser = list[i].users.find(u => u.userId === currentUserId)
          list[i].takesPart = currUser.takesPart
          list[i].paid = currUser.paid
          list[i].takesPartComment = currUser.comment
          list[i].hasTicketsFrom = currUser.hasTicket === 0 ? '' : list[i].users.find(u => u.userId === currUser.hasTicket).name
          list[i].buysTicketsFor = list[i].users.filter(u => u.hasTicket === currentUserId).map(u => u.name)
        }

        this.eventList.next(list)
        return Promise.resolve()
      })
  } */

  /**
   * Returns new event ID
   */
  addEvent(token: string, name: string, place: string, price: string, timestamp: Date, comment: string, groupId: number) {
    const url = `${this.settingsService.apiUrl}/event`
    const body = {
      name,
      place,
      price,
      timestamp: this.formatDate(timestamp),
      comment,
      groupId
    }

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
      .then(result => {
        return Promise.resolve(result.data as number)
      })
  }

  addEventBackground(token: string, eventId: number, image: any) {
    const url = `${this.settingsService.apiUrl}/event/${eventId}/image`
    const body = {

    }

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  }

  getDetails(token: string, eventId: number) {
    const url = `${this.settingsService.apiUrl}/event/${eventId}/user`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.get(url, {}, headers)
      .then(result => {
        const event: IEvent = JSON.parse(result.data)
        return Promise.resolve(event)
      })
  }

  saveDetails(token: string, eventId: number, name: string, place: string, price: string, timestamp: Date, comment: string, cancelled) {
    const url = `${this.settingsService.apiUrl}/event/${eventId}`

    let body = {}

    if (typeof name !== 'undefined')
      body['name'] = name

    if (typeof place !== 'undefined')
      body['place'] = place

    if (typeof price !== 'undefined')
      body['price'] = price

    if (typeof timestamp !== 'undefined')
      body['timestamp'] = this.formatDate(timestamp)

    if (typeof comment !== 'undefined')
      body['comment'] = comment

    if (typeof cancelled !== 'undefined')
      body['cancelled'] = cancelled

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.put(url, body, headers)
  }

  deleteEvent(token: string, eventId: number) {
    const url = `${this.settingsService.apiUrl}/event/${eventId}`
    const body = {}
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.delete(url, body, headers)
  }

  userAdd(token: string, eventId: number, takesPart?: number, userId?: number) {
    const url = `${this.settingsService.apiUrl}/event/${eventId}/user`
    const body = {}

    if (typeof takesPart !== 'undefined')
      body['takesPart'] = takesPart

    if (typeof userId !== 'undefined') {
      body['userId'] = userId
      body['sendNotification'] = true
    }

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  }

  userEdit(token, eventId, hasTicket, buysTickets, takesPart, paid, comment, userId?) {
    let url = `${this.settingsService.apiUrl}/event/${eventId}/user`
    const body = {}

    if (typeof userId !== 'undefined')
      url += `/${userId}`


    if (typeof hasTicket !== 'undefined')
      body['hasTicket'] = hasTicket


    if (typeof buysTickets !== 'undefined')
      body['buysTickets'] = buysTickets


    if (typeof takesPart !== 'undefined')
      body['takesPart'] = takesPart


    if (typeof paid !== 'undefined')
      body['paid'] = paid

    if (typeof comment !== 'undefined')
      body['comment'] = comment

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.put(url, body, headers)
  }

  formatDate(date) {
    if (typeof date === 'undefined')
      return undefined
    const d = new Date(date)
    const
      year = '' + d.getFullYear(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),

      hour = '' + d.getHours(),
      minute = '' + d.getMinutes(),
      second = '00'

    return [[year, month, day].join('-'), [hour, minute, second].join(':')].join(' ')
  }
}

export interface IEvent {
  id: number,
  name: string,
  place: string,
  price: string,
  timestamp: Date,
  groupId?: number,
  groupName?: string,
  comment?: string,
  users?: IEventUser[],
  invitees?: IInvitee[],

  img?: string,

  // additional
  ownerName?: string,
  takesPart?: number,
  paid?: boolean,
  takesPartComment?: string,
  buysTicketsFor?: string[],
  hasTicketsFrom?: string,

  deleted?: boolean,
  cancelled?: boolean
}

export interface IEventUser {
  eventId: number,
  userId: number,
  name: string,
  firstname: string,
  lastname: string,
  owner: boolean,
  hasTicket: number,
  buysTickets: boolean,
  takesPart: number,
  paid: boolean,
  comment: string
}

export interface IEventTicketBuyer {
  name: string,
  buysTicketsFor: string[]
}
