import { Injectable } from '@angular/core'
import { HTTP } from '@ionic-native/http/ngx'
import { NativeStorage } from '@ionic-native/native-storage/ngx'
import { SettingsService } from '../settings/settings.service'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private tables = {
    /**
     * IUser
     */
    currentUser: 'CURRENT_USER'
  }

  currentUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null)

  constructor(
    private http: HTTP,
    private storage: NativeStorage,
    private settingsService: SettingsService
  ) { }

  get isLoggedin() {
    return this.currentUser.value !== null
  }

  initialize() {
    return this.storage.getItem(this.tables.currentUser)
      .then(value => {
        this.currentUser.next(value)
      })
      .catch(() => {
        this.currentUser.next(null)
        return Promise.resolve()
      })
  }

  login(email: string, password: string) {
    const url = `${this.settingsService.apiUrl}/user/login`
    const body = {
      email,
      password
    }
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    return this.http.post(url, body, headers)
      .then(result => {
        const user: IUser = JSON.parse(result.data)
        return this.storage.setItem(this.tables.currentUser, user)
          .then(() => {
            this.currentUser.next(user)
            return Promise.resolve()
          })
      })
  }

  setDeviceToken(token: string, deviceToken: string, removeToken: boolean = false) {
    const url = `${this.settingsService.apiUrl}/user/setdevicetoken`
    const body = {
      deviceToken
    }

    if (removeToken)
      body['removeToken'] = true

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.put(url, body, headers)
      .then(() => {
        const user = this.currentUser.value
        user.deviceToken = removeToken ? null : deviceToken
        this.currentUser.next(user)
      })
  }

  signup(firstname: string, lastname: string, email: string, password: string) {
    const url = `${this.settingsService.apiUrl}/user/signup`
    const body = {
      firstname,
      lastname,
      email,
      password
    }
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    return this.http.post(url, body, headers)
  }

  forgotpw(email: string) {
    const url = `${this.settingsService.apiUrl}/user/forgotpw`
    const body = {
      email
    }
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    return this.http.post(url, body, headers)
  }

  changepw(token: string, password: string) {
    const url = `${this.settingsService.apiUrl}/user/changepw`
    const body = {
      password
    }
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  }

  setNotificationsValues(token: string, allowMailNotifications: boolean, allowPushNotifications: boolean) {
    const url = `${this.settingsService.apiUrl}/user/setnotificationsvalues`
    const body = {}

    if (typeof allowMailNotifications !== 'undefined')
      body['allowMailNotifications'] = allowMailNotifications

    if (typeof allowPushNotifications !== 'undefined')
      body['allowPushNotifications'] = allowPushNotifications

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.put(url, body, headers)
      .then(() => {
        const user = this.currentUser.value
        if (typeof allowMailNotifications !== 'undefined')
          user.allowMailNotifications = allowMailNotifications
        if (typeof allowPushNotifications !== 'undefined')
          user.allowPushNotifications = allowPushNotifications
        return this.storage.setItem(this.tables.currentUser, user)
          .then(() => {
            this.currentUser.next(user)
          })
      })
  }

  logout() {

    // TODO: logout der tokens!

    return this.storage.remove(this.tables.currentUser)
      .then(() => {
        this.currentUser.next(null)
        return Promise.resolve()
      })
  }

  list(token) {
    const url = `${this.settingsService.apiUrl}/user`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.get(url, {}, headers)
      .then(result => {
        const list: IUser[] = JSON.parse(result.data)
        return Promise.resolve(list)
      })
  }
}

export interface IUser {
  id: number,
  name: string,
  username?: string,
  email?: string,
  token?: string,
  allowMailNotifications?: boolean,
  allowPushNotifications?: boolean,
  deviceToken?: string
}
