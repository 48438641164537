import { UserService } from 'src/app/services/user/user.service'
import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService
  ) { }

  canActivate(): boolean {
    return this.userService.isLoggedin
  }
}
