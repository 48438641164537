import { AlertController } from '@ionic/angular'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { AlertInput } from '@ionic/core'
import { ToastController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) { }

  async errorHandlerPrompt(error, showMessage?) {
    if (!environment.production)
      console.error(error)

    if (showMessage) {
      if (typeof showMessage === 'string')
        this.alertPrompt(showMessage, 'Warnung')
      else
        this.alertPrompt('Ein Fehler ist aufgetreten!', 'Warnung')
    }
  }

  errorHandler(error, showMessage?) {
    if (!environment.production)
      console.error(error)

    if (showMessage) {
      if (typeof showMessage === 'string')
        this.toast(showMessage)
      else
        this.toast('Ein Fehler ist aufgetreten!')
    }
  }

  async alertPrompt(message: string, header: string, backdropDismiss = true) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      backdropDismiss,
      buttons: ['OK']
    })

    await alert.present()
  }

  toast(message: string, duration = 3, position: 'bottom' | 'top' | 'middle' = 'bottom') {
    this.toastCtrl.create({
      color: 'dark',
      duration: duration * 1000,
      position,
      message,
      showCloseButton: true,
      closeButtonText: 'Schließen',
    }).then(toast => {
      toast.present()
    })
  }

  confirmPrompt(message: string, header: string, rejectButton = false, backdropDismiss = true) {
    return new Promise(resolve => {
      let result = false
      const buttons: any[] = [{
        text: 'Abbrechen',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          result = null
        }
      }]

      if (rejectButton)
        buttons.push({
          text: 'Ablehnen',
          handler: () => {
            result = false
          }
        })

      buttons.push({
        text: rejectButton ? 'Annehmen' : 'Bestätigen',
        handler: () => {
          result = true
        }
      })

      this.alertCtrl.create({
        header,
        message,
        backdropDismiss,
        buttons
      }).then(alert => {
        alert.present()
        alert.onDidDismiss()
          .then(() => {
            return resolve(result)
          })
      })
    })
  }

  checkboxPrompt(options: any[], header: string, radio = false) {
    return new Promise(resolve => {
      let result = {}
      const inputs: AlertInput[] = options.map(o => {
        return {
          type: radio ? 'radio' : 'checkbox',
          label: o.label,
          value: o.value,
          checked: typeof o.checked === 'boolean' ? o.checked : false,
          handler: (value) => {
            if (!radio)
              result[value.value] = value.checked
          }
        } as AlertInput
      })

      this.alertCtrl.create({
        header,
        inputs,
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              result = {}
            }
          }, {
            text: 'Bestätigen',
            handler: (value) => {
              if (radio)
                result[value] = true
            }
          }
        ]
      }).then(alert => {
        alert.present()
        alert.onDidDismiss()
          .then(() => {
            return resolve(result)
          })
      })
    })
  }

  inputPrompt(options: any[], header: string, backdropDismiss = true) {
    return new Promise(resolve => {
      let result: any = null
      const inputs: AlertInput[] = options.map(o => {
        return {
          type: typeof o.type === 'undefined' ? 'text' : o.type,
          placeholder: o.placeholder,
          name: o.name
        } as AlertInput
      })

      this.alertCtrl.create({
        header,
        inputs,
        backdropDismiss,
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: 'Bestätigen',
            handler: (value) => {
              result = value
            }
          }
        ],
        cssClass: 'input-alert'
      }).then(alert => {
        alert.present()
        alert.onDidDismiss()
          .then(() => {
            return resolve(result)
          })
      })
    })
  }
}
