import { Injectable } from '@angular/core'
import { NativeStorage } from '@ionic-native/native-storage/ngx'
import { DEFAULT_SERVER_URL, DEFAULT_DELETE_OLD_DATA_VALUE } from 'src/app/config'

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private tables = {
    /**
     * string
     */
    serverURL: 'SERVER_URL',
    /**
     * boolean
     */
    deleteOldData: 'DELETE_OLD_DATA'
  }

  serverUrl: string
  deleteOldDataValue: boolean

  constructor(
    private storage: NativeStorage
  ) { }

  initialize() {
    return new Promise(async resolve => {
      try {
        this.serverUrl = await this.storage.getItem(this.tables.serverURL)
      } catch (e) {
        this.serverUrl = DEFAULT_SERVER_URL
      }

      try {
        this.deleteOldDataValue = await this.storage.getItem(this.tables.deleteOldData)
      } catch (e) {
        this.deleteOldDataValue = DEFAULT_DELETE_OLD_DATA_VALUE
      }

      return resolve()
    })
  }

  get apiUrl() {
    return this.serverUrl + '/api'
  }

  setServerUrl(value: string) {
    return this.storage.setItem(this.tables.serverURL, value)
      .then(() => {
        this.serverUrl = value
      })
  }

  setDeleteOldDataValue(value: boolean) {
    return this.storage.setItem(this.tables.deleteOldData, value)
      .then(() => {
        this.deleteOldDataValue = value
      })
  }

}
