import { SettingsService } from '../settings/settings.service'
import { Injectable } from '@angular/core'
import { HTTP } from '@ionic-native/http/ngx'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  invitationsList: BehaviorSubject<IInvitation[]> = new BehaviorSubject<IInvitation[]>([])

  constructor(
    private http: HTTP,
    private settingsService: SettingsService
  ) { }

  updateList(token: string) {
    const url = `${this.settingsService.apiUrl}/invitation`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.get(url, {}, headers)
      .then(result => {
        const list: IInvitation[] = JSON.parse(result.data)
        this.invitationsList.next(list)
        return Promise.resolve(list)
      })
  }

  invite(token, groupId, eventId, email, userId?) {
    const url = `${this.settingsService.apiUrl}/invitation`
    const body = {
    }

    if (email)
      body['email'] = email

    if (userId)
      body['userId'] = userId

    if (groupId)
      body['groupId'] = groupId
    else if (eventId)
      body['eventId'] = eventId


    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  }

  sendConfirmation(token: string, groupId: number, eventId: number, accept: boolean) {
    const url = `${this.settingsService.apiUrl}/invitation`
    const body = {
      accept
    }

    if (groupId !== null)
      body['groupId'] = groupId
    if (eventId !== null)
      body['eventId'] = eventId

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.put(url, body, headers)
  }

  deleteInvitation(token: string, invitationId: number) {
    const url = `${this.settingsService.apiUrl}/invitation/${invitationId}`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.delete(url, {}, headers)
  }
}

export interface IInvitation {
  id: number,
  timestamp: Date,
  inviter: string,
  group?: string,
  groupId?: number,
  event?: string,
  eventId?: number
}

export interface IInvitee {
  invitationId: number,
  name: string,
  email: string
}
