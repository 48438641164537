import { Keyboard } from '@ionic-native/keyboard/ngx'
import { HTTP } from '@ionic-native/http/ngx'
import { NgModule, LOCALE_ID } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { NativeStorage } from '@ionic-native/native-storage/ngx'
import { DatePicker } from '@ionic-native/date-picker/ngx'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { registerLocaleData } from '@angular/common'
import localeDe from '@angular/common/locales/de'

// import { Camera } from '@ionic-native/camera/ngx'
import { File } from '@ionic-native/file/ngx'
import { FilePath } from '@ionic-native/file-path/ngx'
import { WebView } from '@ionic-native/ionic-webview/ngx'

import { FCM } from '@ionic-native/fcm/ngx'

import { AppUpdate } from '@ionic-native/app-update/ngx'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { Calendar } from '@ionic-native/calendar/ngx'

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HTTP,
    Keyboard,
    NativeStorage,
    DatePicker,
    // Camera,
    File,
    FilePath,
    WebView,
    FCM,
    AppUpdate,
    AppVersion,
    Calendar
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe)
  }
}
