import { AlertService } from './../alert/alert.service'
import { Injectable } from '@angular/core'
import { NativeStorage } from '@ionic-native/native-storage/ngx'
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx'
import { File } from '@ionic-native/file/ngx'
import { FilePath } from '@ionic-native/file-path/ngx'
import { SettingsService } from '../settings/settings.service'
import { BehaviorSubject } from 'rxjs'
import { Platform } from '@ionic/angular'
import { WebView } from '@ionic-native/ionic-webview/ngx'
import { HTTP } from '@ionic-native/http/ngx'
import { ALLOWED_EXT } from 'src/app/config'

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  //
  // TODO: TEST ON iOS !!!
  //

  private tables = {
    /**
     * string
     */
    appBackground: 'APP_BACKGROUND',

    /**
     * Array<{ eventId: number, name:string, path: string }>
     */
    eventBackgrounds: 'EVENT_BACKGROUNDS'
  }

  // path to app background image or a base64 string if on browser
  appBackground: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  // isBrowser: boolean

  constructor(
    private storage: NativeStorage,
    private settingsService: SettingsService,
    private file: File,
    // private camera: Camera,
    private platform: Platform,
    private filePath: FilePath,
    private webView: WebView,
    private alert: AlertService,
    private http: HTTP
  ) {
    // this.isBrowser = false document.URL.startsWith('http')
  }

  initialize() {
    return this.getAppBackgroundValue()
      .then(value => {
        // console.log('background: ', value)
        this.appBackground.next(value)
      })
  }

  private getAppBackgroundValue() {
    return this.storage.getItem(this.tables.appBackground)
      .catch(() => Promise.resolve(null))
  }

  private setAppBackgroundValue(value: string) {
    return this.storage.setItem(this.tables.appBackground, value)
      .then(() => {
        this.appBackground.next(value)
        // this.appBackground.next(this.urlPathForImage(value))
      })
  }

  private removeAppBackgroundValue() {
    return this.storage.remove(this.tables.appBackground)
      .then(() => {
        this.appBackground.next(null)
      })
  }

  saveAppBackground(img: string) {
    return this.setAppBackgroundValue(img)
  }

  /* saveAppBackground(path: string) {
    if (this.isBase64ImageFormat(path))
      return this.setAppBackgroundValue(path)
    else
      return this.removeAppBackground()
        .then(() => this.moveFileToDataDirecrtory(path))
        .then((newFilePath: string) => this.setAppBackgroundValue(newFilePath))
  } */

  /**
   * doesn't return any errors
   */
  removeAppBackground() {
    return this.getAppBackgroundValue()
      .then(value =>
        Promise.all([
          this.removeAppBackgroundValue(),
          this.removeFromDevice(value)
        ])
      )
      .catch(() => Promise.resolve())
  }

  private getEventBackgroundsValues(): Promise<IEventBackgroundValue[]> {
    return this.storage.getItem(this.tables.eventBackgrounds)
      .catch(() => Promise.resolve([]))
  }

  private setEventbackgroundsValues(value: IEventBackgroundValue[]) {
    return this.storage.setItem(this.tables.eventBackgrounds, value)
  }

  private removeEventBackgroundsValues() {
    return this.storage.remove(this.tables.eventBackgrounds)
  }

  /**
   * ALWAYS resovles!
   */
  getEventBackgroundValue(eventId: number): Promise<IEventBackgroundValue> {
    return this.getEventBackgroundsValues()
      .then(values => {
        const i = values.findIndex(e => e.eventId === eventId)
        if (i !== -1)
          return Promise.resolve(values[i])
        else
          return Promise.resolve(null)
      })
      .catch(() => Promise.resolve(null))
  }

  addEventBackgroundValue(eventId: number, name: string, path: string) {
    return this.getEventBackgroundsValues()
      .then((values: IEventBackgroundValue[]) => {
        const i = values.findIndex(e => e.eventId === eventId)
        if (i === -1)
          values.push({ eventId, name, path })
        else {
          values[i].name = name
          values[i].path = path
        }

        // console.log(i)
        // console.log(values)

        return this.setEventbackgroundsValues(values)
      })
  }

  saveEventBackground(eventId: number, img: string, name: string) {
    return this.saveOnDevice(img, name)
      .then(path => this.addEventBackgroundValue(eventId, name, path))
  }

  removeEventBackground(eventId: number) {
    return this.getEventBackgroundsValues()
      .then(values => {
        const i = values.findIndex(e => e.eventId === eventId)
        if (i !== -1)
          return this.removeFromDevice(values[i].path)
            .then(() => {
              values.splice(i, 1)
              return this.setEventbackgroundsValues(values)
            })
      })
  }

  cleanupOldEventbackgrounds(exceptionIds: number[]) {
    return this.getEventBackgroundsValues()
      .then(async values => {
        const toDeleteList = values.filter(e => !exceptionIds.includes(e.eventId))

        for (const val of toDeleteList)
          await this.removeFromDevice(val.path)

        values = values.filter(e => exceptionIds.includes(e.eventId))
        return this.setEventbackgroundsValues(values)
      })
  }

  /**
   * @returns Base64 of the selected picture (or null)
   */
  getImageFromDevice(): Promise<string> {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.name = 'files[]'
      input.style.zIndex = '-1'
      input.onchange = (e) => {

        console.log(e.target['files'][0])

        this.fileToBase64(e.target['files'][0])
          .then(resolve)
          .catch(reject)
      }
      document.body.appendChild(input)
      input.click()
    })
  }

  cleanup() {
    const addedInputElements = document.getElementsByName('files[]')
    for (let i = addedInputElements.length - 1; i >= 0; i--)
      addedInputElements[i].parentNode.removeChild(addedInputElements[i])
  }

  /**
   * NUR JPEG.
   * Auf Browser nur base64 (JPEG)
   */
  /* getPictureFromDevice(): Promise<string> {
    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI, // NATIVE_URI,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      // saveToPhotoAlbum: false,
      // correctOrientation: true,
      mediaType: this.camera.MediaType.ALLMEDIA // .PICTURE
    }

    return new Promise((resolve, reject) => {
      this.camera.getPicture(options)
        .then((imageData: string) => {

          console.log('imageData: ', imageData)

          // imageData =  'file:///storage/emulated/0/Download/b55e3bafe484a0ead34d5e3849bd1e11.gif'

          if (this.isBase64ImageFormat(imageData))
            // base64
            resolve('data:image/jpeg;base64,' + imageData)
          else
            this.filePath.resolveNativePath(imageData)
              .then(filePath => {

                console.log('filePath: ', filePath)

                if (this.isAllowedImageFormat(filePath))
                  return resolve(filePath)
                else {
                  this.alert.toast('Bildformat nicht unterstützt')
                  return resolve()
                  // return reject(new Error('Not a JPEG!'))
                }

              })
              .catch(err => {
                console.log('ERROR: ', err)
                reject(err)
              })
        })
        .catch(err => {
          if (typeof err === 'string' && err === 'No Image Selected')
            return resolve()
          else
            return reject(err)
        })
    })
  } */

  saveOnDevice(base64Image: string, name: string) {

    const base64Data = base64Image.substring(base64Image.indexOf(';base64,') + 8)

    let path: string = null

    if (this.platform.is('android'))
      path = this.file.externalDataDirectory

    if (!path)
      // browser
      return Promise.resolve(base64Image)
    else
      // device
      return this.file.writeFile(path, name, this.base64ToArrayBuffer(base64Data), { replace: true })
        .then(() => Promise.resolve(path + name))
  }

  private base64ToArrayBuffer(base64): ArrayBuffer {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer as ArrayBuffer
  }

  /* private moveFileToDataDirecrtory(path: string, newName?: string) {

    //
    // TODO : test new path on ios!?
    //

    const oldPath = path.substring(0, path.lastIndexOf('/') + 1)
    const oldName = path.substring(path.lastIndexOf('/') + 1, path.length)

    // const newPath = this.platform.is('android') ? this.file.externalDataDirectory : this.file.dataDirectory
    const newPath = this.file.externalDataDirectory
    if (!newName)
      newName = Date.now() + '.' + path.split('.').pop().toLowerCase() // '.jpeg'

    return this.file.moveFile(oldPath, oldName, newPath, newName)
      .then(entry => Promise.resolve(entry.nativeURL))
  } */

  /**
   * remove the image from device
   * always resolves
   */
  removeFromDevice(path: string) {
    return new Promise(resolve => {
      if (path.startsWith('data:image/'))
        return resolve()
      else {
        const correctPath = path.substring(0, path.lastIndexOf('/'))
        const correctName = path.substring(path.lastIndexOf('/') + 1, path.length)

        console.log('removing from device')
        console.log(correctPath)
        console.log(correctName)

        this.file.removeFile(correctPath, correctName)
          .then(() => resolve())
          .catch(err => {
            this.alert.errorHandler(err, false)
            return resolve()
          })
      }
    })
  }

  /**
   * 
   * @param isLoggedIn wenn nicht eingelogged wird default hintergrund auch gelöscht
   */
  clearCache(isLoggedIn: boolean) {
    return new Promise((resolve, reject) => {

      //
      // TODO: check for iOS devices
      //
      const cacheDir = this.file.externalCacheDirectory
      const dataDir = this.file.externalDataDirectory

      if (!(cacheDir && dataDir))
        // most probably on browser
        this.removeEventBackgroundsValues()
          .then(() => {
            if (!isLoggedIn)
              return this.removeAppBackgroundValue()
          })
          .then(() => resolve())
          .catch(err => reject(err))
      else
        // on device
        Promise.all([
          this.file.listDir(this.file.externalCacheDirectory, ''),
          this.file.listDir(this.file.externalDataDirectory, ''),
          this.getAppBackgroundValue()
        ])
          .then(async result => {
            const cacheList = result[0].map(c => c.nativeURL)
            const dataList = result[1].map(d => d.nativeURL)
            const appBackground = result[2]

            const toDeleteList = cacheList.concat(dataList).filter(url => url !== appBackground)

            try {
              for (const path of toDeleteList)
                await this.removeFromDevice(path)

              await this.removeEventBackgroundsValues()

              if (!isLoggedIn)
                await this.removeAppBackground()
            }

            finally {
              resolve()
            }

          })
          .catch(err => reject(err))
    })
  }

  checkFileExists(path: string) {
    if (path === null)
      return Promise.reject()
    else if (path.startsWith('data:image/'))
      return Promise.resolve()
    else {
      const p = this.file.externalDataDirectory // .slice(0, this.file.externalDataDirectory.length - 1)
      const n = path.split('/').pop()
      console.log(p)
      console.log(n)
      return this.file.checkFile(p, n)
        .then(result => {
          console.log('exists?: ', result)
          if (result)
            return Promise.resolve()
          else
            return Promise.reject()
        })
    }
  }

  urlPathForImage(path: string) {
    if (path === null)
      return ''
    else if (path.startsWith('data:image/'))
      return path
    else {
      /**
       * TEMPORARY SOLUTION TO WEBVIEW FAILING TO RESOLVE THE PATH
       */
      let url = this.webView.convertFileSrc(path)
      if (url.startsWith('undefined'))
        url = url.replace('undefined', 'http://localhost')
      return url
    }
    /* else
      return this.webView.convertFileSrc(path) */
  }

  /**
   * @param file Resultat von "open file dialog"
   */
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const ext = file.name.split('.').pop().toLowerCase()
      const allowed = ALLOWED_EXT.indexOf(ext) !== -1

      if (allowed) {
        const reader = new FileReader()

        reader.onloadend = () => {
          console.log(reader.result.toString())
          return resolve(reader.result.toString())
        }

        reader.onerror = () => {
          return reject(reader.error)
        }

        reader.readAsDataURL(file)
      }
      else
        reject('Wrong file type!')
    })
  }

  eventImageUpload(token: string, eventId: number, img: string, name: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.settingsService.apiUrl}/event/${eventId}/image`
      const headers = { 'Authorization': 'Bearer ' + token }

      if (!img.startsWith('data:image/')) {

        const body = { fileName: name }

        this.http.uploadFile(url, body, headers, img, 'eventBackground')
          .then(response => resolve(response))
          .catch((err) => reject(err))
      }
      else {
        // console.log('base64 image')
        const body = {
          fileName: name,
          base64Image: img
        }

        this.http.post(url, body, headers)
          .then(result => resolve(result))
          .catch((err) => reject(err))
      }
    })
  }

  /**
   * Returns path to the image if successful
   */
  eventImageDownloadAndSaveOnDevice(token: string, eventId: number, name: string) {
    return new Promise((resolve, reject) => {
      let path: string = null

      if (this.platform.is('android'))
        path = this.file.externalDataDirectory
      // else if(this.platform.is('ios'))
      // path =

      // console.log('path: ', path)

      let url = `${this.settingsService.apiUrl}/event/${eventId}/image`

      const headers = { 'Authorization': 'Bearer ' + token }

      if (!path) {
        url += '?base64Image=true'
        this.http.get(url, {}, headers)
          .then(response => resolve(response.data))
          .catch(err => reject(err))
      }
      else
        this.http.downloadFile(url, {}, headers, path + name)
          // fileTransfer.download(url, path + name, true, options)
          .then(entry => {
            // console.log(entry)
            resolve(entry.nativeURL)
          })
          .catch(err => reject(err))
    })
  }

  eventImageDelete(token: string, eventId: number) {
    const url = `${this.settingsService.apiUrl}/event/${eventId}/image`
    const body = {}
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.delete(url, body, headers)
  }


  createFilenameFromBase64(base64Img: string, timestamp: number) {
    // 'data:image/jpeg;base64,'
    if (!base64Img.startsWith('data:image/'))
      return null

    else {
      const ext = base64Img.substring(11, base64Img.indexOf(';base64,'))

      if (ALLOWED_EXT.indexOf(ext) === -1)
        return null

      return timestamp + '.' + ext
    }
  }

  /* isBase64ImageFormat(img: string) {
    return (
      img.startsWith('/9j/') ||
      img.startsWith('data:image/jpeg;base64,')
    )
  } */

  /* isAllowedImageFormat(path: string) {
    const ext = path.split('.').pop().toLowerCase()
    return ALLOWED_EXT.indexOf(ext) !== -1
  } */
}

export interface IEventBackgroundValue {
  eventId: number,
  name: string,
  path: string
}

/* export interface IBase64Image {
  base64: string
  name: string
  extention: string
} */