import { SettingsService } from '../settings/settings.service'
import { IInvitee } from '../invitation/invitation.service'
import { Injectable } from '@angular/core'
import { HTTP } from '@ionic-native/http/ngx'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  groupList: BehaviorSubject<IGroup[]> = new BehaviorSubject<IGroup[]>([])

  constructor(
    private http: HTTP,
    private settingsService: SettingsService
  ) { }

  updateList(token: string) {
    const url = `${this.settingsService.apiUrl}/group`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.get(url, {}, headers)
      .then(result => {
        const list: IGroup[] = JSON.parse(result.data)
        this.groupList.next(list)
        return Promise.resolve(list)
      })
  }

  getDetails(token: string, groupId: number) {
    const url = `${this.settingsService.apiUrl}/group/${groupId}/user`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.get(url, {}, headers)
      .then(result => {
        const group: IGroup = JSON.parse(result.data)
        return Promise.resolve(group)
      })
  }

  addGroup(token: string, name: string) {
    const url = `${this.settingsService.apiUrl}/group`
    const body = {
      name
    }
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  }

  saveDetails(token: string, groupId: number, name: string) {
    const url = `${this.settingsService.apiUrl}/group/${groupId}`
    const body = {
      name
    }
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.put(url, body, headers)
  }

  deleteGroup(token: string, groupId: number) {
    const url = `${this.settingsService.apiUrl}/group/${groupId}`
    const body = {}
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.delete(url, body, headers)
  }

  userAdd(token: string, groupId: number, userId?: number) {
    const url = `${this.settingsService.apiUrl}/group/${groupId}/user`
    const body = {}

    if (typeof userId !== 'undefined')
      body['userId'] = userId

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  }

  userDelete(token, groupId, userId, self = false) {
    let url = `${this.settingsService.apiUrl}/group/${groupId}/user/${userId}`

    if (self)
      url += '?self=true'

    const body = {}

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.delete(url, body, headers)
  }

  /* userRequestVerify(token, groupId) {
    const url = `${API_URL_GROUP}/${groupId}/user/request-verify`
    const body = {}

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  }

  userConfirmVerify(token, groupId, userId) {
    const url = `${API_URL_GROUP}/${groupId}/user/confirm-verify`
    const body = {
      userId
    }

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.post(url, body, headers)
  } */

}

export interface IGroup {
  id: number,
  name: string,
  users?: IGroupUser[],
  invitees?: IInvitee[]
}

export interface IGroupUser {
  groupId: number,
  userId: number,
  name: string,
  firstname: string,
  lastname: string,
  owner: boolean,
  verified: boolean
}
