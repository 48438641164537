import { AuthGuard } from './services/auth-guard/auth-guard.service'
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule', canActivate: [AuthGuard] },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'signup', loadChildren: './pages/signup/signup.module#SignupPageModule' },
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule' },
  { path: 'changepw', loadChildren: './pages/changepw/changepw.module#ChangepwPageModule', canActivate: [AuthGuard] },
  {
    path: 'event-details', loadChildren: './pages/event-details/event-details.module#EventDetailsPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'event-details/:eventId', loadChildren: './pages/event-details/event-details.module#EventDetailsPageModule',
    canActivate: [AuthGuard]
  },
  { path: 'event-new', loadChildren: './pages/event-new/event-new.module#EventNewPageModule', canActivate: [AuthGuard] },
  { path: 'group', loadChildren: './pages/group/group.module#GroupPageModule', canActivate: [AuthGuard] },
  {
    path: 'group-details/:groupId', loadChildren: './pages/group-details/group-details.module#GroupDetailsPageModule',
    canActivate: [AuthGuard]
  },
  { path: 'forgotpw', loadChildren: './pages/forgotpw/forgotpw.module#ForgotpwPageModule' },
  { path: 'howto', loadChildren: './pages/howto/howto.module#HowtoPageModule', canActivate: [AuthGuard] },
  { path: 'howto-event', loadChildren: './pages/howto-event/howto-event.module#HowtoEventPageModule' },
  { path: 'howto-group', loadChildren: './pages/howto-group/howto-group.module#HowtoGroupPageModule' },
  { path: 'howto-invitation', loadChildren: './pages/howto-invitation/howto-invitation.module#HowtoInvitationPageModule' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
