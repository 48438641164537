import { AlertService } from './../alert/alert.service'
import { ImageService } from './../image/image.service'
import { SettingsService } from '../settings/settings.service'
import { GroupService, IGroup } from 'src/app/services/group/group.service'
import { EventService, IEvent } from '../event/event.service'
import { Injectable } from '@angular/core'
import { HTTP } from '@ionic-native/http/ngx'
import { InvitationService, IInvitation } from '../invitation/invitation.service'

import { AppUpdate } from '@ionic-native/app-update/ngx'

@Injectable({
  providedIn: 'root'
})
export class ExtraService {

  constructor(
    private http: HTTP,
    private eventService: EventService,
    private groupService: GroupService,
    private invitationService: InvitationService,
    private settingsService: SettingsService,
    private imgService: ImageService,
    private alert: AlertService,
    private appUpdate: AppUpdate
  ) { }

  updateAllLists(token: string, currentUserId: number) {
    const url = this.settingsService.apiUrl
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    }

    return this.http.get(url, {}, headers)
      .then(result => {
        const lists: { eventsList: IEvent[], groupsList: IGroup[], invitationsList: IInvitation[] } = JSON.parse(result.data)

        // console.log(lists)

        this.eventService.setEventsList(lists.eventsList, currentUserId)
        this.groupService.groupList.next(lists.groupsList)
        this.invitationService.invitationsList.next(lists.invitationsList)

        return Promise.resolve(lists.eventsList.map(e => e.id))
      })
      .then((eventsIds: number[]) => {
        if (this.settingsService.deleteOldDataValue)
          this.imgService.cleanupOldEventbackgrounds(eventsIds)
            .catch((err) => this.alert.errorHandler(err, false))
      })
  }

  getLatestAppVersionCode(): Promise<number> {
    const url = this.settingsService.serverUrl + '/appversion'
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    return this.http.get(url, {}, headers)
      .then(result => Promise.resolve(parseInt(result.data, 10)))
  }

  updateApp(username, password) {
    const url = this.settingsService.serverUrl + '/appversion/update'
    const options = {
      skipPromptDialog: true,
      authType: 'basic',
      username,
      password
    }

    console.log('updating app!')
    return this.appUpdate.checkAppUpdate(url, options).then(console.log)
  }
}
